@import "stylesheets/bootstrap-shared";

.hidden-xl {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.hidden-lg-up {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.hidden-md-up {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.hidden-sm-up {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.hidden-xs-up {
  @include media-breakpoint-up(xs) {
    display: none;
  }
}

.hidden-lg-down {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.hidden-md-down {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.hidden-sm-down {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.hidden-xs {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}
