.button-mini {
  padding: $btn-padding-y-mini $btn-padding-x-mini;
  border-radius: 10px;
  font-size: 9px;
}

.button-small {
  padding: $btn-padding-y-sm $btn-padding-x-sm;
  border-radius: 15px;
  font-size: 10px;
}

.button-medium {
  padding: $btn-padding-y $btn-padding-x;
  border-radius: 20px;
}

.button-large {
  padding: $btn-padding-y-lg $btn-padding-x-lg;
  border-radius: 25px;
  font-size: 14px;
}

.button-extra-large {
  padding: $btn-padding-y-xl $btn-padding-x-xl;
  border-radius: 25px;
  font-size: 14px;
}
