$body-bg: #202f3b;
$body-color: #8f979d;

$btn-border-radius-lg: 30px !default; // stylelint-disable-line annotation-no-unknown

$btn-padding-x-mini: 1rem !default; // stylelint-disable-line annotation-no-unknown
$btn-padding-y-mini: .3rem !default; // stylelint-disable-line annotation-no-unknown

$btn-padding-x: 1.4rem !default; // stylelint-disable-line annotation-no-unknown
$btn-padding-y: .5rem !default; // stylelint-disable-line annotation-no-unknown

$btn-padding-x-lg: 1.9rem !default; // stylelint-disable-line annotation-no-unknown
$btn-padding-y-lg: .6rem !default; // stylelint-disable-line annotation-no-unknown

$btn-padding-x-xl: 2.9rem !default; // stylelint-disable-line annotation-no-unknown
$btn-padding-y-xl: .9rem !default; // stylelint-disable-line annotation-no-unknown

$card-bg: #202f3b;
$card-border-width: 0;
$card-inner-border-radius: 10px;

$container-max-widths: (
  sm: 540px,
  md: 1040px,
  lg: 1220px,
  xl: 1400px
);

$dropdown-bg: #131e27;
$dropdown-border-radius: 0;
$dropdown-item-padding-x: 50px;
$dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg: #1f2b36;
$dropdown-link-color: #8f979d;
$dropdown-padding-y: 50px;

$font-family-base: "exo";

$grid-breakpoints: (
  xs: 0,
  sm: 670px,
  md: 1060px,
  lg: 1240px,
  xl: 1440px
);

$headings-color: #fff;

$input-bg: #0d151c;
$input-border-color: #0d151c;
$input-color: #fff;
$input-group-addon-bg: #4e606f;
$input-placeholder-color: #4e606f;

$link-color: #fff;

$navbar-height: 85px;
$navbar-nav-link-padding-y: 3rem;
$navbar-padding-y: 0;
$navbar-toggler-padding-x: 0;

$pagination-bg: #192631;
$pagination-border-width: 0;
$pagination-disabled-bg: #313c47;
$pagination-hover-bg: #313c47;

$pre-color: $body-color;

$primary: #ee9625;
$danger: #d9534f;
