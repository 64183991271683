// stylelint-disable no-descending-specificity
// stylelint-disable selector-max-compound-selectors
.card {
  background: transparent;

  > .card-header {
    background: #0d151c;
  }

  > .card-body {
    border-radius: 0 0 10px 10px;
    background: #1d2b37;
  }

  > .card-body:only-child > .table {
    > thead > tr > th:first-child {
      border-radius: 10px 0 0;
    }

    > thead > tr > th:last-child {
      border-radius: 0 10px 0 0;
    }

    // If there is just a single column, one of the rules would override the other
    > thead > tr > th:first-child:last-child {
      border-radius: 10px 10px 0 0;
    }
  }

  > .card-body > .table {
    > thead > tr {
      background: #131e27;
    }

    > thead > tr > th {
      padding: 20px;
      border: 0;
      color: #8f979d;
      font-weight: normal;
    }

    > thead > tr > th > a:link,
    > thead > tr > th > a:visited {
      color: #8f979d;
    }

    > tbody > tr > th {
      color: #fff;
      font-weight: normal;
    }

    > tbody > tr {
      background: #192631;
    }

    > tbody > tr:hover {
      background: #3a4957;
    }

    > tbody > tr:not(:last-child) > td,
    > tbody > tr:not(:last-child) > th {
      border-bottom: 1px solid #394956;
    }

    > tbody > tr > td,
    > tbody > tr > th {
      padding: 20px;
      border: 0;
    }
  }
}
// stylelint-enable
