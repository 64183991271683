.font-sm {
  font-size: 12px;
}

.font-md {
  font-size: 1rem;
}

.font-lg {
  font-size: 18px;
}

.font-xl {
  font-size: 28px;
}
